
export default {
    props: {
        tableName: String
    },
    data() {
        return {
            pageDropdownValue: 0
        }
    },
    computed: {
        rowCount() {
            return this.$store.state.rowCount || 0
        },
        pageSize() {
            return this.$store.state.pageSize || 1
        },
        pageCount() {
            let count = Math.ceil(this.rowCount / this.pageSize)
            return count < 1 ? 1 : count
        },
        currentTableName() {
            return this.$store.state.currentTableName
        },
        filterParams() {
            return this.$store.state.filterParams
        },
        currentPageNumber() {
            return this.$store.state.currentPageNumber
        }
    },
    methods: {
        async changePage(pageNumber) {
            if (this.currentPageNumber != pageNumber) {
                console.log("Navigating to " + pageNumber)
                this.$store.commit("updateCurrentPageNumber", pageNumber)
                await this.$store.dispatch("fetchRows")
            }
        }
    },
    watch: {
        pageDropdownValue(value) {
            this.changePage(value)
        }
    },
    mounted() {
        this.pageDropdownValue = this.currentPageNumber
    }
}
