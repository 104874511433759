
import Vue from "vue"

export default Vue.extend({
    computed: {
        formConfigs() {
            return this.$store.state.formConfigs
        }
    },
    async mounted() {
        await this.$store.dispatch("fetchFormConfigs")
    }
})
