var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"tooltip"},[_c('a',{attrs:{"href":"#","title":"Click for more info"},on:{"click":function($event){$event.preventDefault();_vm.popupVisible ? _vm.hidePopup($event) : _vm.showPopup($event)},"blur":function($event){return _vm.hidePopup()}}},[_c('font-awesome-icon',{attrs:{"icon":"question-circle"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.popupVisible),expression:"popupVisible"}],staticClass:"popup",style:({
            top: _vm.popupTop,
            bottom: _vm.popupBottom,
            right: _vm.popupRight,
            left: _vm.popupLeft,
            width: _vm.popupWidth,
            textAlign: _vm.textAlign
        })},[_c('span',[_vm._v(_vm._s(_vm.content))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }