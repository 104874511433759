
import Vue, { PropType } from "vue"

export default Vue.extend({
    props: {
        tableName: String as PropType<string>
    },
    computed: {
        currentTableName(): string {
            return this.$store.state.currentTableName
        },
        readableTableName(): string {
            return this.tableName.split("_").join(" ")
        }
    },
    methods: {
        isActive(tableName: string): boolean {
            return this.currentTableName === tableName
        }
    }
})
