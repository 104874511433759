
import Vue, { PropType } from "vue"

export default Vue.extend({
    props: {
        message: {
            type: String as PropType<string>,
            default: "Uploading file"
        }
    }
})
