
import BaseView from "./BaseView.vue"

export default {
    components: {
        BaseView
    },
    computed: {
        siteName() {
            return this.$store.state.metaModule.siteName
        }
    },
    mounted() {
        this.$store.commit("updateCurrentTablename", "")
    }
}
