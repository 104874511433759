
import Vue from "vue"
import NavDropDownMenu from "./NavDropDownMenu.vue"
import SidebarOverlay from "./SidebarOverlay.vue"
import NavTranslationsMenu from "./NavTranslationsMenu.vue"

export default Vue.extend({
    props: {
        username: {
            type: String,
            default: "Unknown"
        },
        siteName: {
            type: String,
            default: "Piccolo Admin"
        }
    },
    data() {
        return {
            showSidebar: false,
            showDropdown: false,
            showLanguageDropdown: false
        }
    },
    computed: {
        truncatedUsername() {
            const username = this.username
            if (username.length > 20) {
                return username.slice(0, 16) + "..."
            } else {
                return username
            }
        }
    },
    components: {
        SidebarOverlay,
        NavDropDownMenu,
        NavTranslationsMenu
    },
    methods: {
        closeDropdown(value) {
            this.showLanguageDropdown = value
        }
    }
})
