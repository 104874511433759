
import Vue, { PropType } from "vue"
import ChoiceSelect from "./ChoiceSelect.vue"
import MediaViewer from "./MediaViewer.vue"
import { Choices, MediaViewerConfig } from "@/interfaces"

export default Vue.extend({
    props: {
        array: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        inputType: {
            type: String as PropType<string>,
            default: "text"
        },
        fieldName: {
            type: String as PropType<string>,
            default: ""
        },
        enableAddButton: {
            type: Boolean as PropType<boolean>,
            default: true
        },
        isFilter: {
            type: Boolean as PropType<boolean>,
            default: true
        },
        choices: {
            type: Object as PropType<Choices>,
            default: () => {
                return {}
            }
        },
        isNullable: {
            type: Boolean as PropType<boolean>,
            default: false
        }
    },
    components: {
        ChoiceSelect,
        MediaViewer
    },
    data() {
        return {
            internalArray: [],
            showMediaViewer: false,
            mediaViewerConfig: null as MediaViewerConfig
        }
    },
    computed: {
        schema() {
            return this.$store.state.schema
        },
        currentTableName() {
            return this.$store.state.currentTableName
        },
        isMediaColumn() {
            return this.schema.media_columns.includes(this.fieldName)
        }
    },
    methods: {
        getValueFromEvent(event: Event) {
            return (event.target as HTMLInputElement).value
        },
        updateArray(value: any, index: number) {
            this.$set(this.internalArray, index, value)
            this.$emit("updateArray", this.internalArray)
        },
        addArrayElement() {
            this.internalArray = [...this.internalArray, ""]
            this.$emit("updateArray", this.internalArray)
        },
        removeArrayElement(index: number) {
            this.$delete(this.internalArray, index)
            this.$emit("updateArray", this.internalArray)
        },
        showMedia(index: number) {
            const mediaViewerConfig: MediaViewerConfig = {
                fileKey: this.internalArray[index],
                columnName: this.fieldName,
                tableName: this.currentTableName
            }
            this.mediaViewerConfig = mediaViewerConfig
            this.showMediaViewer = true
        }
    },
    watch: {
        array() {
            this.internalArray = [...this.array]
        }
    },
    mounted() {
        this.internalArray = [...this.array]
    }
})
