
import { defineComponent } from "vue"
import Modal from "./Modal.vue"
import * as i from "../interfaces"
import { syncQueryParams, getOrderByString } from "@/utils"

export default defineComponent({
    props: {
        schema: Object,
        tableName: String
    },
    data() {
        return {
            localCopy: null as i.OrderByConfig[] | null
        }
    },
    components: {
        Modal
    },
    methods: {
        async save() {
            const localCopy: i.OrderByConfig[] = this.localCopy
            // Remove any which didn't specify a column to sort by.
            const orderByConfigs = localCopy.filter((i) => i.column)

            this.$store.commit("updateOrderBy", orderByConfigs)
            syncQueryParams({ __order: getOrderByString(orderByConfigs) })

            await this.$store.dispatch("fetchRows")

            this.$emit("close")
        },
        addOrderByColumn() {
            const newValue: i.OrderByConfig = {
                column: null,
                ascending: true
            }
            this.localCopy.push(newValue)
        },
        removeOrderByColumn(index: number) {
            this.localCopy.splice(index, 1)
        }
    },
    mounted() {
        let orderByConfigs: i.OrderByConfig[] | null = this.$store.state.orderBy

        let localCopy: i.OrderByConfig[] = orderByConfigs
            ? orderByConfigs.map((i) => {
                  return { ...i }
              })
            : []

        this.localCopy = localCopy
    }
})
