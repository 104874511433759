
import Vue, { computed, PropType, reactive, toRefs, watch } from "vue"

import KeySearch from "./KeySearch.vue"
import InputField from "./InputField.vue"
import Tooltip from "./Tooltip.vue"
import { Schema } from "@/interfaces"

export default Vue.extend({
    props: {
        row: Object,
        schema: Object as PropType<Schema>,
        isFilter: {
            type: Boolean,
            default: false
        }
    },
    components: {
        InputField,
        KeySearch,
        Tooltip
    },
    setup(props) {
        const { schema, row } = toRefs(props)

        const foreignKeyColumnNames = computed(() => {
            const _foreignKeyColumnNames = []

            Object.entries(schema.value.properties).forEach((value) => {
                const [columnName, property] = value
                if (property.extra.foreign_key == true) {
                    _foreignKeyColumnNames.push(columnName)
                }
            })

            return _foreignKeyColumnNames
        })

        const foreignKeyIDs = computed(() => {
            const _foreignKeyIDs = {}
            foreignKeyColumnNames.value.forEach((columnName) => {
                _foreignKeyIDs[columnName] = row.value[columnName]
            })

            return reactive(_foreignKeyIDs)
        })

        return {
            foreignKeyIDs
        }
    },
    methods: {
        getValue(columnName: string) {
            let value = this.row ? this.row[columnName] : undefined
            return value
        },
        isRequired(columnName: string) {
            return (
                !this.isFilter &&
                (this.schema.required || []).indexOf(columnName) != -1
            )
        },
        isMediaColumn(columnName: string) {
            return this.schema.media_columns.includes(columnName)
        },
        isRichText(columnName: string) {
            return this.schema.rich_text_columns.includes(columnName)
        }
    }
})
