
import { PropType, defineComponent } from "vue"

import KeySearch from "./KeySearch.vue"
import InputField from "./InputField.vue"
import { Schema } from "@/interfaces"

export default defineComponent({
    props: {
        schema: Object as PropType<Schema>
    },
    components: {
        InputField,
        KeySearch
    },
    methods: {
        getValue(columnName: string) {
            const schema: Schema = this.schema
            if (schema.properties[columnName].type == "boolean") {
                return "all"
            } else {
                return null
            }
        }
    }
})
