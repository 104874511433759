
import Vue, { PropType } from "vue"
import RowForm from "./RowForm.vue"
import FormErrors from "./FormErrors.vue"
import { APIResponseMessage } from "../interfaces"
import { parseErrorResponse } from "../utils"
import { Schema } from "@/interfaces"
import { convertFormValue } from "@/utils"

export default Vue.extend({
    props: {
        tableName: String as PropType<string>,
        schema: Object as PropType<Schema>
    },
    components: {
        RowForm,
        FormErrors
    },
    data() {
        return {
            defaults: {} as { [key: string]: any },
            errors: [] as string[]
        }
    },
    methods: {
        async submitForm(event) {
            const form = new FormData(event.target)

            const json = {}
            for (const i of form.entries()) {
                const key = i[0]
                let value = i[1]

                json[key] = convertFormValue({
                    key,
                    value,
                    schema: this.schema
                })
            }
            try {
                await this.$store.dispatch("createRow", {
                    tableName: this.tableName,
                    data: json
                })
            } catch (error) {
                this.errors = parseErrorResponse(
                    error.response.data,
                    error.response.status
                )

                window.scrollTo(0, 0)

                var message: APIResponseMessage = {
                    contents: "The form has errors.",
                    type: "error"
                }
                this.$store.commit("updateApiResponseMessage", message)

                return
            }
            this.errors = []

            var message: APIResponseMessage = {
                contents: "Successfully added row",
                type: "success"
            }
            this.$store.commit("updateApiResponseMessage", message)

            this.$emit("addedRow")
            this.$emit("close")
        }
    },
    async mounted() {
        let response = await this.$store.dispatch("getNew", this.tableName)
        this.defaults = response.data
    }
})
