
import Vue, { PropType } from "vue"

export default Vue.extend({
    props: {
        errors: {
            type: Array as PropType<string[]>,
            default: () => []
        }
    }
})
