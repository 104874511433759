
import Vue from "vue"
import BackButton from "./BackButton.vue"
import NavBar from "./NavBar.vue"

export default Vue.extend({
    components: {
        BackButton,
        NavBar,
    },
    computed: {
        username() {
            const user = this.$store.state.user
            return user ? user.username : "Unknown"
        },
        siteName() {
            return this.$store.state.metaModule.siteName
        },
    },
})
