
import axios from "axios"
import Vue from "vue"

import DropDownMenu from "./DropDownMenu.vue"

export default Vue.extend({
    components: {
        DropDownMenu
    },
    computed: {
        darkMode() {
            return this.$store.state.darkMode
        }
    },
    methods: {
        updateDarkMode(enabled: boolean) {
            this.$store.commit("updateDarkMode", enabled)
        },
        showAboutModal() {
            this.$store.commit("updateShowAboutModal", true)
        },
        async logout() {
            if (window.confirm("Are you sure you want to logout?")) {
                console.log("Logging out")
                try {
                    await axios.post("./public/logout/")
                    // Reload the entire page, rather than using vue-router,
                    // otherwise some data from Vuex will remain in memory.
                    // The app will redirect the user to the login page
                    // after the reload.
                    location.replace(window.location.pathname)
                } catch (error) {
                    console.log("Logout failed")
                    console.log(error)
                }
            }
        }
    }
})
