
import Vue from "vue"
import DropDownMenu from "./DropDownMenu.vue"
import { TranslationListItemAPI } from "@/interfaces"

export default Vue.extend({
    components: {
        DropDownMenu
    },
    methods: {
        async updateLanguage(languageCode: string) {
            await this.$store.dispatch("loadTranslation", languageCode)
            this.$emit("closeDropdown", false)
        }
    },
    computed: {
        translations(): TranslationListItemAPI[] {
            return this.$store.state.translationsModule.translations
        },
        currentLanguageCode(): string {
            return this.$i18n.locale || "en"
        }
    }
})
