
import Vue from "vue"
import TableNavItem from "./TableNavItem.vue"

export default Vue.extend({
    components: {
        TableNavItem
    },
    data() {
        return { hiddenGroups: [] }
    },
    computed: {
        tableGroups() {
            return this.$store.state.tableGroups
        },
        currentTableName() {
            return this.$store.state.currentTableName
        }
    },
    methods: {
        toggleGroup(groupName: string) {
            const hiddenGroups: string[] = this.hiddenGroups
            const index = hiddenGroups.indexOf(groupName)
            if (index == -1) {
                hiddenGroups.push(groupName)
            } else {
                hiddenGroups.splice(index, 1)
            }
            this.hiddenGroups = hiddenGroups
        }
    },
    async mounted() {
        await this.$store.dispatch("fetchTableGroups")
    }
})
