
import Modal from "./Modal.vue"

export default {
    components: {
        Modal
    },
    computed: {
        piccoloAdminVersion() {
            return this.$store.state.metaModule.piccoloAdminVersion
        }
    },
    methods: {
        hideAboutModal() {
            this.$store.commit("updateShowAboutModal", false)
        }
    }
}
