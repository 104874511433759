
export default {
    props: {
        content: {
            default: "",
            type: String
        }
    },
    data() {
        return {
            popupVisible: false,
            popupTop: "auto",
            popupBottom: "auto",
            popupLeft: "auto",
            popupRight: "auto",
            popupWidth: undefined,
            textAlign: undefined
        }
    },
    methods: {
        showPopup(event) {
            const scrollHeight = document.body.scrollHeight
            const scrollWidth = document.body.scrollWidth
            const eventY = event.pageY
            const eventX = event.pageX

            if (eventX < 150) {
                console.log("Right")
                this.popupLeft = eventX + 20 + "px"
                this.popupRight = "auto"
                this.popupWidth = scrollWidth - eventX - 20 + "px"
                this.textAlign = "left"
            } else if (scrollWidth - eventX < 150) {
                console.log("Left")
                this.popupLeft = "auto"
                this.popupRight = eventX - 20 + "px"
                this.popupWidth = eventX - 20 + "px"
                this.textAlign = "right"
            } else {
                console.log("Center")
                this.popupWidth = "300px"
                this.popupLeft = eventX - 150 + "px"
                this.popupRight = "auto"
                this.textAlign = "center"
            }

            if (scrollHeight - eventY < 300) {
                console.log("Above")
                this.popupBottom = scrollHeight - eventY + 20 + "px"
                this.popupTop = "auto"
            } else {
                console.log("Below")
                this.popupBottom = "auto"
                this.popupTop = eventY + 20 + "px"
            }

            this.popupVisible = true
        },
        hidePopup() {
            this.popupVisible = false
        }
    },
    mounted() {
        let app = this
        function onResize() {
            app.popupVisible = false
        }
        this.onResize = onResize
        window.addEventListener("resize", onResize)
    },
    destroyed() {
        if (this.onResize) {
            window.removeEventListener("resize", this.onResize)
        }
    }
}
