
import Vue, {PropType} from "vue"
import InputField from "../components/InputField.vue"
import KeySearch from "../components/KeySearch.vue"
import Modal from "../components/Modal.vue"
import * as i from "../interfaces"

export default Vue.extend({
    props: {
        schema: Object as PropType<i.Schema>,
        tableName: String,
        selectedRows: {
            type: Array,
            default: () => []
        }
    },
    components: {
        Modal,
        InputField,
        KeySearch
    },
    data() {
        return {
            selectedPropertyName: null,
            nullCheckboxState: false,
            buttonEnabled: true
        }
    },
    computed: {
        selectedProperty() {
            return this.schema.properties[this.selectedPropertyName]
        }
    },
    methods: {
        async updateRows(event) {
            console.log("Updating ...")

            // We prevent the button from being clicked again until we've
            // finished, as it can cause many API requests.
            this.buttonEnabled = false

            const form = new FormData(event.target)

            const json = {}

            let value = null

            if (!this.nullCheckboxState) {
                value = form.get(this.selectedPropertyName)

                if (this.selectedProperty.type == "array") {
                    value = JSON.parse(value)
                }
            }

            json[this.selectedPropertyName] = value

            try {
                // TODO - we will use the new bulk update endpoint once it is
                // merged into PiccoloCRUD.
                for (let i = 0; i < this.selectedRows.length; i++) {
                    await this.$store.dispatch("updateRow", {
                        tableName: this.tableName,
                        rowID: this.selectedRows[i],
                        data: json
                    })
                }
                var message: i.APIResponseMessage = {
                    contents: "Successfully updated rows",
                    type: "success"
                }
                this.$store.commit("updateApiResponseMessage", message)

                this.$emit("close")

                await this.$store.dispatch("fetchRows")
            } catch (error) {
                console.log(error)
                var message: i.APIResponseMessage = {
                    contents: "Invalid column name or value",
                    type: "error"
                }
                this.$store.commit("updateApiResponseMessage", message)
            }

            this.buttonEnabled = true
        }
    }
})
