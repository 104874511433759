
import { defineComponent } from "vue"
import KeySearchModal from "./KeySearchModal.vue"

export default defineComponent({
    props: {
        fieldName: String,
        tableName: String,
        rowID: undefined,
        readable: undefined,
        isFilter: {
            type: Boolean,
            default: false
        },
        isNullable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ids: [],
            selectedValue: undefined,
            hiddenSelectedValue: undefined,
            showModal: false
        }
    },
    methods: {
        handleUpdate(event) {
            this.selectedValue = event.readable
            this.hiddenSelectedValue = event.id
            this.showModal = false
        }
    },
    components: {
        KeySearchModal
    },
    watch: {
        readable(newValue) {
            this.selectedValue = newValue
        },
        rowID(newValue) {
            this.hiddenSelectedValue = newValue
        }
    },
    async mounted() {
        this.selectedValue = this.readable
        this.hiddenSelectedValue = this.rowID
    }
})
