
import axios from "axios"
import BackButton from "../components/BackButton.vue"

export default {
    data() {
        return {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        }
    },
    components: {
        BackButton
    },
    methods: {
        async changePassword() {
            console.log("Changing password")
            const payload = {
                current_password: this.currentPassword,
                new_password: this.newPassword,
                confirm_new_password: this.confirmNewPassword
            }
            try {
                await axios.post(`./api/change-password/`, payload)
                this.$store.commit("updateApiResponseMessage", {
                    contents: `Changed password successfully. You will be redirected
                        to the login page to log in with your new credentials.`,
                    type: "success"
                })
                setTimeout(() => {
                    this.$router.push({ name: "home" })
                }, 3000)
            } catch (error) {
                console.log("Request failed")
                console.log(error.response)
                this.$store.commit("updateApiResponseMessage", {
                    contents: `Error - ${error.response.data.detail}`,
                    type: "error"
                })
            }
        }
    }
}
