var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.row)?_c('div',_vm._l((_vm.schema.properties),function(property,columnName){return _c('div',{key:columnName},[_c('label',[_vm._v(" "+_vm._s(property.title)+" "),(_vm.isRequired(String(columnName)))?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e(),(property.extra.help_text)?_c('Tooltip',{attrs:{"content":property.extra.help_text}}):_vm._e(),(property.extra.foreign_key)?[(!_vm.isFilter)?_c('router-link',{staticClass:"add",attrs:{"to":{
                        name: 'addRow',
                        params: { tableName: property.extra.to }
                    },"target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1):_vm._e(),(!_vm.isFilter && _vm.foreignKeyIDs[columnName])?_c('router-link',{staticClass:"add",attrs:{"to":{
                        name: 'editRow',
                        params: {
                            tableName: property.extra.to,
                            rowID: _vm.foreignKeyIDs[columnName]
                        }
                    },"target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":"edit"}})],1):_vm._e()]:_vm._e()],2),(property.extra.foreign_key)?_c('KeySearch',{attrs:{"fieldName":String(columnName),"tableName":property.extra.to,"rowID":_vm.getValue(String(columnName)),"readable":_vm.getValue(columnName + '_readable'),"isNullable":property.nullable},on:{"update":function($event){_vm.$set(_vm.foreignKeyIDs, String(columnName), $event.id)}}}):_c('InputField',{attrs:{"columnName":columnName,"format":property.format,"isFilter":_vm.isFilter,"isNullable":property.nullable,"required":_vm.isRequired(String(columnName)),"title":property.title,"type":property.type || property.anyOf[0].type,"value":_vm.getValue(String(columnName)),"choices":property.extra.choices,"isMediaColumn":_vm.isMediaColumn(String(columnName)),"isRichText":_vm.isRichText(String(columnName))}})],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }