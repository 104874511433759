
import axios from "axios"
import Vue from "vue"
import { PropType } from "vue"

import { MediaViewerConfig } from "@/interfaces"

// @ts-ignore
const BASE_URL = process.env.VUE_APP_BASE_URI

const AUDIO_EXTENSIONS = ["mp3", "wav"]
const IMAGE_EXTENSIONS = ["gif", "jpeg", "jpg", "png", "svg", "tif", "webp"]
const VIDEO_EXTENSIONS = ["mp4", "webm"]

export default Vue.extend({
    props: {
        mediaViewerConfig: {
            type: Object as PropType<MediaViewerConfig>
        }
    },
    data() {
        return {
            fileURL: null
        }
    },
    methods: {
        isAudio(fileKey: string): boolean {
            const components = fileKey.split(".")
            const extension = components[components.length - 1].toLowerCase()
            return AUDIO_EXTENSIONS.indexOf(extension) != -1
        },
        isImage(fileKey: string): boolean {
            const components = fileKey.split(".")
            const extension = components[components.length - 1].toLowerCase()
            return IMAGE_EXTENSIONS.indexOf(extension) != -1
        },
        isPDF(fileKey: string): boolean {
            const components = fileKey.split(".")
            const extension = components[components.length - 1].toLowerCase()
            return extension.toLowerCase() == "pdf"
        },
        isVideo(fileKey: string): boolean {
            const components = fileKey.split(".")
            const extension = components[components.length - 1].toLowerCase()
            return VIDEO_EXTENSIONS.indexOf(extension) != -1
        },
        async generateFileURL({
            columnName,
            tableName,
            fileKey
        }: MediaViewerConfig) {
            const response = await axios.post(
                `${BASE_URL}media/generate-file-url/`,
                {
                    column_name: columnName,
                    table_name: tableName,
                    file_key: fileKey
                }
            )
            return response.data["file_url"]
        }
    },
    watch: {
        async mediaViewerConfig(mediaViewerConfig: MediaViewerConfig) {
            if (mediaViewerConfig) {
                this.fileURL = await this.generateFileURL(mediaViewerConfig)
            }
        }
    },
    async mounted() {
        if (this.mediaViewerConfig) {
            this.fileURL = await this.generateFileURL(this.mediaViewerConfig)
        }
    }
})
