
import { PropType, defineComponent } from "vue"
import { Choices } from "../interfaces"

export default defineComponent({
    props: {
        fieldName: {
            type: String as PropType<string>,
            default: ""
        },
        value: {
            type: undefined,
            default: undefined
        },
        choices: {
            type: Object as PropType<Choices>,
            default: () => {
                return {}
            }
        },
        isNullable: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        isFilter: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        isArray: {
            type: Boolean as PropType<boolean>,
            default: false
        }
    },
    data() {
        return {
            localValue: ""
        }
    },
    emits: ["updated"],
    mounted() {
        this.localValue = this.isFilter ? "all" : this.value
    },
    watch: {
        value(newValue) {
            this.localValue = newValue
        },
        localValue(newValue) {
            this.$emit("updated", newValue)
        }
    }
})
