
import Vue from "vue"
import AddRowForm from "../components/AddRowForm.vue"
import DetailViewBase from "../components/DetailViewBase.vue"

export default Vue.extend({
    props: ["tableName"],
    components: {
        AddRowForm,
        DetailViewBase,
    },
    computed: {
        schema() {
            return this.$store.state.schema
        },
    },
    async mounted() {
        this.$store.commit("updateCurrentTablename", this.tableName)
        await this.$store.dispatch("fetchSchema", this.tableName)
    },
})
