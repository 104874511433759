
import Vue from "vue"
import FormAdd from "../components/FormAdd.vue"
import DetailViewBase from "../components/DetailViewBase.vue"

export default Vue.extend({
    props: ["formSlug"],
    components: {
        FormAdd,
        DetailViewBase,
    },
    computed: {
        schema() {
            return this.$store.state.formSchema
        },
    },
    async mounted() {
        await this.$store.dispatch("fetchFormSchema", this.formSlug)
    },
})
