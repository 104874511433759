
import Vue, {PropType} from "vue"
import InputField from "./InputField.vue"
import {Schema} from "@/interfaces"

export default Vue.extend({
    props: {
        schema: Object as PropType<Schema>,
    },
    components: {
        InputField,
    },
})
