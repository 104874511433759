
import { FetchIdsConfig } from "../interfaces"
import Modal from "./Modal.vue"
import { titleCase } from "../utils"

const PAGE_SIZE = 5

export default {
    props: {
        tableName: String,
        isFilter: {
            type: Boolean,
            default: false
        },
        isNullable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            ids: [],
            offset: 0,
            limitReached: false,
            searchTerm: "",
            debounceTimer: null
        }
    },
    components: {
        Modal
    },
    computed: {
        readableTableName() {
            return titleCase(this.tableName.split("_").join(" "))
        }
    },
    methods: {
        async fetchData() {
            // We fetch one more row, so we know if we've hit the limit or not.
            const config: FetchIdsConfig = {
                tableName: this.tableName,
                search: this.searchTerm,
                limit: PAGE_SIZE + 1,
                offset: this.offset
            }

            const response = await this.$store.dispatch("fetchIds", config)
            const ids = Object.entries(response.data)

            this.limitReached = ids.length <= PAGE_SIZE

            return ids.slice(0, PAGE_SIZE)
        },
        scrollResultsToBottom() {
            setTimeout(() => {
                const listElement =
                    document.getElementsByClassName("results_list")[0]
                listElement.scrollIntoView(false)
            }, 0)
        },
        async loadMore() {
            this.offset += PAGE_SIZE
            const ids = await this.fetchData()
            this.ids.push(...ids)
            this.scrollResultsToBottom()
        },
        selectResult(id, readable) {
            this.$emit("update", { id, readable })
        }
    },
    watch: {
        async tableName() {
            this.offset = 0
            this.ids = await this.fetchData()
        },
        async searchTerm() {
            // We debounce it, to reduce the number of API calls.
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer)
            }

            let app = this

            this.debounceTimer = setTimeout(async () => {
                app.offset = 0
                app.ids = await app.fetchData()
            }, 300)
        }
    },
    async mounted() {
        this.ids = await this.fetchData()
    }
}
