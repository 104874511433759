
import Vue, { PropType } from "vue"
import axios from "axios"
import flatPickr from "vue-flatpickr-component"
import { VueEditor } from "vue2-editor"

import ArrayWidget from "./ArrayWidget.vue"
import ChoiceSelect from "./ChoiceSelect.vue"
import DurationWidget from "./DurationWidget.vue"
import LoadingOverlay from "./LoadingOverlay.vue"
import MediaViewer from "./MediaViewer.vue"
import OperatorField from "./OperatorField.vue"
import {
    Choices,
    StoreFileAPIResponse,
    APIResponseMessage,
    MediaViewerConfig
} from "@/interfaces"

export default Vue.extend({
    props: {
        // A nicely formatted column name, for example 'First Name'
        title: {
            type: String as PropType<string>,
            required: true
        },
        columnName: {
            type: String as PropType<string>,
            required: true
        },
        type: {
            type: String as PropType<string>,
            default: "string"
        },
        value: {
            type: undefined as PropType<any>,
            default: undefined
        },
        // Fields can share the same type, but have different formats. For
        // example, 'text-area', when type is 'string'.
        format: {
            type: String as PropType<string | undefined>,
            default: undefined
        },
        isFilter: {
            type: Boolean as PropType<boolean>,
            default: true
        },
        isNullable: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        choices: {
            type: Object as PropType<Choices>,
            default: null
        },
        isMediaColumn: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        isRichText: {
            type: Boolean as PropType<boolean>,
            default: false
        }
    },
    components: {
        flatPickr,
        ArrayWidget,
        ChoiceSelect,
        DurationWidget,
        LoadingOverlay,
        MediaViewer,
        OperatorField,
        VueEditor
    },
    data() {
        return {
            localValue: undefined,
            textareaHeight: "50px",
            showMediaViewer: false,
            mediaViewerConfig: null as MediaViewerConfig,
            customToolbar: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["link", "image", "code-block"],
                [{ header: [false, 1, 2, 3] }]
            ],
            showLoadingOverlay: false
        }
    },
    computed: {
        placeholder() {
            if (this.isFilter) {
                return "All"
            } else if (this.isNullable && this.value === null) {
                return "NULL"
            } else {
                return ""
            }
        },
        currentTableName() {
            return this.$store.state.currentTableName
        }
    },
    methods: {
        setTextareaHeight() {
            let element = this.$refs.textarea
            if (element) {
                if (element.scrollHeight > element.clientHeight) {
                    this.textareaHeight = element.scrollHeight + "px"
                }
            }
        },
        updateLocalValue(event) {
            this.localValue = event
        },
        showMedia() {
            const mediaViewerConfig: MediaViewerConfig = {
                fileKey: this.localValue,
                columnName: this.columnName,
                tableName: this.currentTableName
            }
            this.mediaViewerConfig = mediaViewerConfig
            this.showMediaViewer = true
        },
        async uploadFile(event) {
            const file = event.target.files[0]

            if (!file) {
                return
            }

            let formData = new FormData()
            formData.append("table_name", this.currentTableName)
            formData.append("column_name", this.columnName)
            formData.append("file", file)

            this.showLoadingOverlay = true

            try {
                const response = await axios.post<StoreFileAPIResponse>(
                    "./api/media/",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                )
                if (this.type == "array") {
                    if (this.localValue) {
                        this.localValue.push(response.data.file_key)
                    } else {
                        this.localValue = [response.data.file_key]
                    }
                } else {
                    this.localValue = response.data.file_key
                }
            } catch (error) {
                let errorMessage = "The request failed."
                const statusCode = error.response?.status

                if (statusCode) {
                    if (statusCode == 413) {
                        errorMessage = "The file is too large."
                    } else if (statusCode == 500) {
                        errorMessage = "An error happened on the server."
                    } else {
                        errorMessage =
                            error.response?.data?.detail ?? "Unknown error"
                    }
                }

                let message: APIResponseMessage = {
                    contents: errorMessage,
                    type: "error"
                }
                this.$store.commit("updateApiResponseMessage", message)
            }

            event.target.value = ""
            this.showLoadingOverlay = false
        }
    },
    watch: {
        value() {
            this.localValue = this.value
            this.setTextareaHeight()
        },
        currentTableName() {
            this.localValue = undefined
        }
    },
    mounted() {
        this.localValue = this.value

        let app = this
        setTimeout(function () {
            app.setTextareaHeight()
        }, 0)
    }
})
