
import Vue from "vue"
import NavBar from "../components/NavBar.vue"
import SidebarNav from "../components/SidebarNav.vue"

export default Vue.extend({
    components: {
        NavBar,
        SidebarNav
    },
    computed: {
        username() {
            const user = this.$store.state.user
            return user ? user.username : "Unknown"
        },
        siteName() {
            return this.$store.state.metaModule.siteName
        }
    }
})
