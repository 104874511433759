
import Vue from "vue"
import { readableInterval } from "../utils"

import AddRowModal from "../components/AddRowModal.vue"
import BaseView from "./BaseView.vue"
import BulkUpdateModal from "../components/BulkUpdateModal.vue"
import BulkDeleteButton from "../components/BulkDeleteButton.vue"
import CSVButton from "../components/CSVButton.vue"
import DeleteButton from "../components/DeleteButton.vue"
import DropDownMenu from "../components/DropDownMenu.vue"
import ChangePageSize from "../components/ChangePageSize.vue"
import MediaViewer from "../components/MediaViewer.vue"
import Pagination from "../components/Pagination.vue"
import RowFilter from "../components/RowFilter.vue"
import OrderByModal from "../components/OrderByModal.vue"
import Tooltip from "../components/Tooltip.vue"
import {
    APIResponseMessage,
    Choice,
    Choices,
    Schema,
    MediaViewerConfig,
    OrderByConfig
} from "../interfaces"
import { deserialiseOrderByString } from "@/utils"

export default Vue.extend({
    props: ["tableName"],
    data() {
        return {
            selectedRows: [],
            allSelected: false,
            showAddRow: false,
            showFilter: false,
            showSortModal: false,
            showUpdateModal: false,
            visibleDropdown: null,
            showMediaViewer: false,
            mediaViewerConfig: null as MediaViewerConfig
        }
    },
    components: {
        AddRowModal,
        BaseView,
        BulkDeleteButton,
        BulkUpdateModal,
        ChangePageSize,
        CSVButton,
        DeleteButton,
        DropDownMenu,
        MediaViewer,
        Pagination,
        RowFilter,
        OrderByModal,
        Tooltip
    },
    computed: {
        visibleColumnNames() {
            return this.schema.visible_column_names
        },
        rows() {
            return this.$store.state.rows
        },
        schema(): Schema {
            return this.$store.state.schema
        },
        orderBy(): OrderByConfig[] | null {
            return this.$store.state.orderBy
        },
        orderByMapping(): { [key: string]: OrderByConfig } {
            const orderBy: OrderByConfig[] | null = this.orderBy

            if (!orderBy) {
                return {}
            }

            return Object.fromEntries(orderBy.map((i) => [i.column, i]))
        },
        rowCount() {
            return this.$store.state.rowCount
        },
        currentPageNumber() {
            return this.$store.state.currentPageNumber
        },
        loadingStatus() {
            return this.$store.state.loadingStatus
        },
        pkName() {
            return this.schema?.primary_key_name || "id"
        },
        linkColumnName(): string {
            let schema: Schema = this.schema
            return schema.link_column_name
        },
        // We create an object for quickly mapping a choice value to it's
        // display value. It maps column name -> choice value -> display value.
        // For example {'genre': {1: 'Sci-Fi'}}
        choicesLookup() {
            let schema: Schema = this.schema
            const output = {}

            for (const [columnName, config] of Object.entries(
                schema.properties
            )) {
                const choices: Choices = config.extra.choices

                const reducer = (accumulator: Object, choice: Choice) => {
                    accumulator[choice.value] = choice.display_name
                    return accumulator
                }

                if (choices) {
                    output[columnName] = Object.values(choices).reduce(
                        reducer,
                        {}
                    )
                } else {
                    output[columnName] = null
                }
            }
            return output
        }
    },
    filters: {
        abbreviate(value: string | null) {
            // We need to handle null values, and make sure text strings aren't
            // too long.
            if (value === null) {
                return null
            }
            let string = String(value)
            if (string.length > 100) {
                return string.substring(0, 80) + "..."
            }
            return string
        },
        humanReadable(value) {
            return readableInterval(value)
        },
        formatJSON(value: string) {
            return JSON.stringify(JSON.parse(value), null, 2)
        }
    },
    methods: {
        isForeignKey(name: string): boolean {
            let property = this.schema.properties[name]
            return property != undefined ? property.extra.foreign_key : false
        },
        isBoolean(name: string): boolean {
            return this.schema.properties[name]["type"] == "boolean"
        },
        isInterval(name: string): boolean {
            return this.schema.properties[name]["format"] == "time-delta"
        },
        isJSON(name: string): boolean {
            return this.schema.properties[name]["format"] == "json"
        },
        isArray(name: string): boolean {
            return this.schema.properties[name]["type"] == "array"
        },
        isMediaColumn(name: string): boolean {
            return this.schema.media_columns.includes(name)
        },
        getTableName(name: string) {
            // Find the table name a foreign key refers to:
            return this.schema.properties[name].extra.to
        },
        closeSideBar(value) {
            this.showFilter = value
        },
        resetRowCheckbox() {
            this.allSelected = false
            this.selectedRows = []
        },
        selectRow() {
            this.allSelected = false
        },
        selectAllRows() {
            // Select all checkboxes and add row ids to selected array:
            if (this.allSelected) {
                this.selectedRows = this.rows.map((row) => row[this.pkName])
            } else {
                this.selectedRows = []
            }
        },
        showSuccess(contents: string) {
            var message: APIResponseMessage = {
                contents: contents,
                type: "success"
            }
            this.$store.commit("updateApiResponseMessage", message)
        },
        async showMedia(fileKey: string, columnName: string) {
            const tableName = this.$store.state.currentTableName
            this.mediaViewerConfig = {
                fileKey,
                columnName,
                tableName
            }
            this.showMediaViewer = true
        },
        async deleteRow(rowID) {
            if (confirm(`Are you sure you want to delete row ${rowID}?`)) {
                console.log("Deleting!")
                await this.$store.dispatch("deleteRow", {
                    tableName: this.tableName,
                    rowID
                })
                await this.fetchRows()
                this.showSuccess("Successfully deleted row")
            }
        },
        async deleteRows() {
            if (confirm(`Are you sure you want to delete the selected rows?`)) {
                console.log("Deleting rows!")
                for (let i = 0; i < this.selectedRows.length; i++) {
                    await this.$store.dispatch("deleteRow", {
                        tableName: this.tableName,
                        rowID: this.selectedRows[i]
                    })
                }
                await this.fetchRows()
                this.showSuccess("Successfully deleted rows")
            }
        },
        async fetchRows() {
            await this.$store.dispatch("fetchRows")
        },
        async fetchSchema() {
            await this.$store.dispatch("fetchSchema", this.tableName)

            const orderBy = this.$route.query.__order as string
            console.log(orderBy)
            if (orderBy) {
                this.$store.commit(
                    "updateOrderBy",
                    deserialiseOrderByString(orderBy)
                )
            } else {
                this.$store.commit(
                    "updateOrderBy",
                    (this.schema as Schema).order_by
                )
            }
        }
    },
    watch: {
        "$route.params.tableName": async function () {
            this.$store.commit("reset")
            this.$store.commit("updateCurrentTablename", this.tableName)
            await this.fetchSchema()
            await this.fetchRows()
        },
        "$route.query": async function () {
            this.$store.commit(
                "updateFilterParams",
                this.$router.currentRoute.query
            )
            await this.fetchRows()
        },
        rows() {
            this.resetRowCheckbox()
        }
    },
    async mounted() {
        this.$store.commit("updateCurrentTablename", this.tableName)

        this.$store.commit(
            "updateFilterParams",
            this.$router.currentRoute.query
        )

        await this.fetchSchema()
        await this.fetchRows()
    }
})
