
import Modal from "./Modal.vue"
import AddRowForm from "./AddRowForm.vue"

export default {
    props: {
        tableName: String,
        schema: Object
    },
    components: {
        Modal,
        AddRowForm
    }
}
