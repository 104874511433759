
import Vue from "vue"
import * as i from "../interfaces"

export default Vue.extend({
    data() {
        return {
            visible: false,
            timeLastAppeared: 0
        }
    },
    computed: {
        message(): string {
            return this.apiResponseMessage
                ? this.apiResponseMessage.contents
                : "-"
        },
        apiResponseMessage(): i.APIResponseMessage {
            return this.$store.state.apiResponseMessage
        }
    },
    methods: {
        getTime(): number {
            return new Date().getTime()
        }
    },
    watch: {
        visible() {
            this.timeLastAppeared = this.getTime()
        },
        apiResponseMessage() {
            this.visible = true
            const app = this

            setTimeout(() => {
                const now = this.getTime()
                // Only hide if it if it wasn't subsequently opened again.
                if (now - app.timeLastAppeared >= 3000) {
                    app.visible = false
                }
            }, 3000)
        }
    }
})
